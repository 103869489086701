import propTypes from 'prop-types';
import React from 'react';
import { PaymentMethodDetail, PaymentMethodIcon, PaymentMethodItem, PaymentSelectedIcon, PaymentTitle } from '../style';
import { BsCash, BsCreditCard2Front, BsCheck2 } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';


export default function PaymentMethod(props) {
    const { t } = useTranslation();

    return <PaymentMethodItem onClick={props.onClick}>
        <PaymentMethodDetail>
            {props.card ?
                <>
                    <PaymentMethodIcon>
                        <BsCreditCard2Front />
                    </PaymentMethodIcon>
                    <PaymentTitle>
                        {t('payment.card')}
                    </PaymentTitle>
                </>
                : <>
                    <PaymentMethodIcon>
                        <BsCash />
                    </PaymentMethodIcon>
                    <PaymentTitle>
                        {t('payment.cash')}
                    </PaymentTitle>
                </>
            }
        </PaymentMethodDetail>
        {props.selected && <PaymentSelectedIcon >
            <BsCheck2 />
        </PaymentSelectedIcon>}
    </PaymentMethodItem>;
}

PaymentMethod.propTypes = {
    card: propTypes.bool,
    cardNumber: propTypes.string,
    selected: propTypes.bool,
    onClick: propTypes.func,
}

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../components/PageHeader';
import { AddressDetailBody, AddressName, AddressDetailContainer, AddressData, AddressMap, AddressAutocomplete, AddressIcon, AddressReference } from './style';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { InputSearch } from '../../components/InputSearch';
import Footer from '../../components/Footer';
import { GoogleMap, Autocomplete, LoadScript, Marker } from '@react-google-maps/api';
import { useGoBack } from '../../hooks/useGoBack';
import { MdLocationPin } from 'react-icons/md';
import { useAddress } from '../../context/address';
import truncateWithDots from '../../helpers/truncateWithDots';
import { getLocationDescription } from '../../helpers/getLocationDescription';
import { useRouteNavigation } from '../../hooks/useRouteNavigation';


export default function AddressDetail() {
    const { t } = useTranslation();
    const goBack = useGoBack();
    const goToRoute = useRouteNavigation();
    const { deliveryAddress } = useAddress();
    const [address, setAddress] = deliveryAddress;

    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [location, setLocation] = useState(address.description ?? '');
    const [additionalInfo, setAdditionalInfo] = useState(address.additionalInfo ?? '');

    /** Google Map library */
    const [libraries] = useState(['places']); // Constant for GoogleMap LoadScript
    const [autocomplete, setAutocomplete] = useState(null);

    //Google Map
    const options = {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
    };

    const containerStyle = {
        maxWidth: '350px',
        height: '500px'
    };

    const onLocationChange = async (value) => {
        const newLocation = value.latLng;
        const lat = newLocation.lat();
        const lng = newLocation.lng();

        setCenter({ lat: lat, lng: lng });

        const newDescription = await getLocationDescription(lat, lng);

        setLocation(newDescription);
    }

    //Autocomplete
    const onLoad = (autocomplete) => {
        setAutocomplete(autocomplete);
    }

    const onPlaceChanged = () => {
        const newLocation = autocomplete.getPlace();
        const newLat = newLocation.geometry.location.lat();
        const newLng = newLocation.geometry.location.lng();
        const newName = newLocation.name;
        const newDescription = newLocation.formatted_address;

        setLocation(`${newName}. ${newDescription}`);
        setCenter({ lat: newLat, lng: newLng });
    }

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCenter({ lat: position.coords.latitude, lng: position.coords.longitude })
            }, () => {
                setCenter({ lat: 20.686354662108293, lng: -103.35074492821934 });
            });
        } else {
            setCenter({ lat: 20.686354662108293, lng: -103.35074492821934 });
        }
    }

    /** */

    useEffect(() => {
        if (address.lat && address.lng) {
            setCenter({ lat: address.lat, lng: address.lng });
        } else {
            getLocation();
        }
    }, [address]);

    const addAddress = async () => {
        setAddress({ lat: center.lat, lng: center.lng, description: location, additionalInfo: additionalInfo });
        goToRoute('/cart');
    }

    return <AddressDetailContainer>
        <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            libraries={libraries}
        >

            <AddressDetailBody>
                <PageHeader title={t('addresses.detail')} onClick={goBack} />
                <AddressMap>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        zoom={16}
                        center={center}
                        options={options}
                        onClick={onLocationChange}
                    >
                        <Marker position={center}
                            draggable={true}
                            onDragEnd={onLocationChange}
                        />
                    </GoogleMap>
                    <AddressAutocomplete>
                        <Autocomplete
                            onLoad={onLoad}
                            onPlaceChanged={onPlaceChanged}>
                            <InputSearch
                                name="autocomplete"
                                placeholder={t('addresses.searchLocation')}
                            />
                        </Autocomplete>
                    </AddressAutocomplete>
                </AddressMap>
                <AddressData>
                    <AddressName>
                        <AddressIcon>
                            <MdLocationPin />
                        </AddressIcon>
                        {truncateWithDots(location, 100)}
                    </AddressName>
                    <AddressReference>
                        <Input
                            name="additionalInfo"
                            placeholder={t('addresses.additionalInfo')}
                            value={additionalInfo}
                            onChange={(event) => { setAdditionalInfo(event.target.value) }}
                        />
                    </AddressReference>
                </AddressData>
            </AddressDetailBody>
        </LoadScript>
        <Footer>
            <Button title={t('addresses.addAddress')} onClick={addAddress} disabled={location.length === 0} />
        </Footer>
    </AddressDetailContainer>;
}

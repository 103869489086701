import React, { useState } from 'react';
import { CartContainer, CartContent, CartBody, CartOptions, CartHelpInfo, CartHelpIcon } from './style';
import { useTranslation } from "react-i18next";
import CartItem from './CartItem';
import DeliveryOptions from './DeliveryOptions';
import CartPayment from './CartPayment';
import PageHeader from '../../components/PageHeader';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import { useCart } from '../../context/cart';
import { useGoBack } from '../../hooks/useGoBack';
import EmptyCart from './EmptyCart';
import CartModal from './CartModal';
import ChannelModal from './ChannelModal';
import { useRouteNavigation } from '../../hooks/useRouteNavigation';
import { payOrder } from '../../services/payments/payOrder';
import { ToastContainer, toast } from 'react-toastify';
import formatNumber from '../../helpers/formatNumber';
import { MdInfo } from 'react-icons/md';
import { useShop } from '../../context/shop';
import { useAddress } from '../../context/address';
import { CancelledOrderAlert } from './CancelledOrderAlert';

export default function Cart() {
    const { t } = useTranslation();

    const { cart, total } = useCart();
    const { payment, deliveryOption } = useShop();
    const [pay,] = payment;
    const [delivery,] = deliveryOption;
    const { deliveryAddress } = useAddress();
    const [address,] = deliveryAddress;
    const [showModal, setShowModal] = useState(false);
    const [modalChannels, setModalChannels] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const goBack = useGoBack();
    const goToRoute = useRouteNavigation();

    const payWithCash = async () => {
        if (cart.clientId === 'qrcode') {
            setModalChannels(true)
        } else {
            toast.success(t('payment.paySuccess'));
            let params = {
                channelId: cart.channelId, 
                username: cart.clientUsername,
                text: 'CONTINUE_ORDER', 
                idSale: cart.id, 
                paymentMethod: pay,
                location: {
                    lat: address.lat,
                    long: address.lng,
                },
                address: address.additionalInfo ? `${address.description}. ${address.additionalInfo}` : address.description
            }
            const url = await payOrder(params);
            window.open('https://'+url, '_blank');
        }
        sessionStorage.removeItem('cart');
    }

    return cart.products?.length > 0 ?
        <CartContainer>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="colored"
                type="info"
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <CartBody>
                <PageHeader title={t('cart.title')} onClick={goBack} />
                <CartOptions>
                    <div>
                        <CartContent>
                            {cart.products.map((product, index) =>
                                <div key={`${product.lineId}-${index}`}>
                                    <CartItem productIndex={index} product={product}
                                        onClick={() => { setShowModal(true); setSelectedProduct(product) }}
                                    />
                                    {showModal &&
                                        <CartModal
                                            show={showModal}
                                            item={selectedProduct}
                                            onClose={() => { setShowModal(false) }}
                                        />
                                    }
                                </div>
                            )
                            }
                        </CartContent>
                        <CartHelpInfo>
                            <CartHelpIcon>
                                <MdInfo />
                            </CartHelpIcon>
                            {t('cart.swipeInfo')}
                        </CartHelpInfo>
                        <DeliveryOptions />
                    </div>
                    <CartPayment showAlert={(value) => setShowAlert(value)} deliveryOption={deliveryOption} />
                </CartOptions>
            </CartBody>
            <Footer>
                <Button title={`${t('cart.pay')} ${formatNumber(total)}`} onClick={() => { pay === 'cash' ? payWithCash() : goToRoute('/payment-info') }}
                    disabled={delivery && !address.lat}
                />
                {cart.clientId === 'qrcode' && <ChannelModal
                    show={modalChannels}
                    onClose={() => { setModalChannels(false) }} />}
            </Footer >
        </CartContainer >
        :
        <>
            <EmptyCart />
            <CancelledOrderAlert show={showAlert} />
        </>
        ;
}

import React, { useState, useEffect } from "react";
import { HomePageContainer, HomePromos } from "../style";
import ImageGallery from "react-image-gallery";
import propTypes from "prop-types";
import { HomeCategories } from "./HomeCategories";
import { getPromotions } from "../../../services/getPromotions";
import { useParams } from "react-router";
import "../../../styles/home-image-gallery.scss";

export const HomePage = ({ showInfo }) => {
  const [promotions, setPromotions] = useState([]);
  const { storeId } = useParams();

  useEffect(() => {
    getPromotions(storeId).then(setPromotions);
  }, [storeId]);

  const images = promotions.map((img) => {
    return {
      original: img.imageUrl[0],
      description: img.descriptionProduct,
    };
  });

  return (
    <HomePageContainer>
      {promotions && promotions.length > 0 && (
        <HomePromos>
          <ImageGallery
            items={images}
            additionalClass="image"
            showThumbnails={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showNav={false}
            showBullets={!showInfo}
            autoPlay
            slideDuration={450}
          />
        </HomePromos>
      )}
      <HomeCategories />
    </HomePageContainer>
  );
};

HomePage.propTypes = {
  categories: propTypes.array,
  showInfo: propTypes.bool,
};


export const getPromotions = async (campaignId) => {
    const url = `${process.env.REACT_APP_TEST_URL}/get-promotion?campaign=${campaignId}`;
    // const url = `${process.env.REACT_APP_TEST_URL}/get-promotion?campaign=61dc864fb38faa18a457f922`;
    const resp = await fetch(url);
    const data = await resp.json();
    const promotions = data.map(promotion => {
        const imageUrl = Array.isArray(promotion.imageUrl) ? promotion.imageUrl : [promotion.imageUrl];
        return {
            _id: promotion._id,
            name: promotion.name,
            promotion: promotion.promotion,
            descriptionProduct: promotion.descriptionProduct,
            salePrice: promotion.salePrice,
            imageUrl,
        }
    })
    return promotions;
}
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Navigate, Outlet, useParams } from "react-router";
import AddressDetail from "../pages/AddressDetail";
import Addresses from "../pages/Addresses";
import Orders from "../pages/Orders";
import Home from "../pages/Home";
import Cart from "../pages/Cart";
import PaymentInfo from "../pages/PaymentInfo";
import ProductDetail from "../pages/ProductDetail";
import PaymentOptions from "../pages/PaymentOptions";
import SubcategoriesByCategory from "../pages/SubcategoriesByCategory";
import ProductsByCategory from "../pages/ProductsByCategory";
import Profile from "../pages/Profile";
import { useCart } from "../context/cart";
import { useShop } from "../context/shop";
import Main from "../layouts/Main";
import ProductEdit from "../pages/ProductEdit";
import AllProductsByCategory from "../pages/AllProductsByCategory";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path=":storeId/:clientId" element={<Marketplace />}>
        {/* Subcategories page */}
        <Route
          path="category/:categoryId"
          element={<SubcategoriesByCategory />}
        />
        {/* Products of a selected category page */}
        <Route
          path="category/:categoryId/products"
          element={<ProductsByCategory />}
        />
        {/* All products in a category, including its subcategories' products */}
        <Route
          path="category/:categoryId/all"
          element={<AllProductsByCategory />}
        />
        {/* Product detail page  */}
        <Route
          path="category/:categoryId/products/:productId"
          element={<ProductDetail />}
        />
        <Route path="home" element={<Home />} />
        <Route path="edit-product/:productLineId" element={<ProductEdit />} />
        <Route path="cart" element={<Cart />} />
        <Route path="payment-info" element={<PaymentInfo />} />
        <Route path="orders" element={<Orders />} />
        <Route path="address-detail" element={<AddressDetail />} />
        <Route path="addresses" element={<Addresses />} />
        <Route path="payment-options" element={<PaymentOptions />} />
        <Route path="profile" element={<Profile />} />
        <Route path="" element={<Navigate to="home" />} />
        <Route path="*" element={<Navigate to="home" />} />
      </Route>
    </Routes>
  );
};

const Marketplace = () => {
  const { loadShop, loadCategories } = useShop();
  const { loadCart } = useCart();
  const { storeId, clientId } = useParams();

  useEffect(() => {
    loadShop(storeId);
  }, [loadShop, storeId]);

  useEffect(() => {
    loadCategories(storeId);
  }, [loadCategories, storeId]);

  useEffect(() => {
    loadCart(storeId, clientId);
  }, [loadCart, storeId, clientId]);

  return (
    <Main>
      <Outlet />
    </Main>
  );
};

import axios from 'axios';

export const getCategories = async (campaignId) => {
    const url = `${process.env.REACT_APP_TEST_URL}/categories/get?campaign=${campaignId}`;

    const { data } = await axios.get(url);

    const categories = data.response;

    return categories;
}



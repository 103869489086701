import styled from "styled-components";

export const PaymentOptionsComponent = styled.div` 
    display: flex;
    flex-direction: column;
    background-color: ${prop => prop.theme.colors.background};
    padding: 20px;
    padding-bottom: 0px;
    height: calc(100% - 20px);
`
export const PaymentOptionsBody = styled.div` 
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: white;
`

export const PaymentMethodsTitle = styled.div` 
    color: ${prop => prop.theme.colors.grayMedium};
    padding: 20px 10px;
`
export const PaymentMethodItem = styled.div` 
    height: 40px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
    }
`
export const PaymentMethodDetail = styled.div` 
    display: flex;
    align-items: center;
    gap: 15px;
    color: black;
`
export const PaymentMethodIcon = styled.div` 
    font-size: 40px;
`
export const PaymentTitle = styled.div` 
    font-size: 18px;
    margin-bottom: 10px;
`
export const PaymentSelectedIcon = styled.div` 
    font-size: 25px;
    font-weight: bold;
`
export const PaymentsAdd = styled.div` 
   color: ${prop => prop.theme.colors.secondary};
   padding: 20px 10px;
`
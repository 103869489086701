import axios from 'axios';

export const getStore = async (campaignId) => {
    const url = `${process.env.REACT_APP_TEST_URL}/get-shop-campaign?campaign=${campaignId}`;
    //const url = `${process.env.REACT_APP_TEST_URL}/get-shop-campaign?campaign=61dc864fb38faa18a457f922`;

    const { data } = await axios.get(url);

    return data;
}

import React from 'react';
import propTypes from 'prop-types';
import { CounterButton, CounterContainer, CounterValue } from './style';
import { BiMinus, BiPlus } from 'react-icons/bi';
export default function ProductCounter({ value, onAdd, onDelete, stock, totalQuantity, totalQuantityEdit }) {
    return <CounterContainer>
        <CounterButton counterValue={value} onClick={onDelete} >
            <BiMinus />
        </CounterButton>
        <CounterValue>
            {value}
        </CounterValue>
        <CounterButton onClick={onAdd} stockMax={ value === stock || totalQuantity === undefined || totalQuantityEdit === 0 } >
            <BiPlus />
        </CounterButton>
    </CounterContainer>
}

ProductCounter.propTypes = {
    value: propTypes.number,
    onAdd: propTypes.func,
    onDelete: propTypes.func,
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentMethodsTitle, PaymentOptionsBody, PaymentOptionsComponent, PaymentsAdd } from './style';
import PageHeader from '../../components/PageHeader';
import PaymentMethod from './PaymentMethod';
import { useRouteNavigation } from '../../hooks/useRouteNavigation';
import { useShop } from '../../context/shop';


export default function PaymentOptions() {
    const { t } = useTranslation();
    const canAdd = false;
    const { payment } = useShop();
    const [pay, setPay] = payment;
    const goToRoute = useRouteNavigation();

    return <PaymentOptionsComponent>
        <PageHeader title={t('payment.options')} onClick={() => goToRoute('/cart')} />
        <PaymentOptionsBody>
            <PaymentMethodsTitle>
                {t('payment.methods')}
            </PaymentMethodsTitle>
            <PaymentMethod card selected={pay === 'card'} onClick={() => { setPay('card') }} />
            <PaymentMethod selected={pay === 'cash'} onClick={() => { setPay('cash') }} />
            {canAdd && <PaymentsAdd>
                {t('payment.add')}
            </PaymentsAdd>}
        </PaymentOptionsBody>
    </PaymentOptionsComponent>;
}

import styled, { css } from "styled-components";

export const CartPaymentContainer = styled.div` 
    background-color: white;
    padding: 10px 0px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    height: 35%;
`

export const CartTotalContainer = styled.div` 
    background-color: white;
    height: 100px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 30px;
`

export const CartSubtotal = styled.div` 
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    color: ${prop => prop.theme.colors.grayMedium};
`

export const CartDelivery = styled(CartSubtotal)` 
    font-size: 18px;
    text-align: center; 
`
export const CartTotal = styled(CartSubtotal)` 
    font-size: 18px;
    font-weight: bold;
    color: black;
    text-align: center; 
`
export const CartPaymentMethod = styled.div` 
    font-size: 18px;
    font-weight: 500;
    color: black;
    background-color: ${prop => prop.theme.colors.blueLight};
    margin-top: 20px;
    padding: 8px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ cash }) => cash && css`
        background-color: ${prop => prop.theme.colors.green};
      `
    }
`

export const CartPaymentNameContainer = styled.div` 
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    font-weight: bold;   
    font-size: 30px;
`

export const CartPaymentName = styled.div` 
    font-weight: 500;
    font-size: 18px;
`

export const CartPaymentCancel = styled.div` 
    color: ${prop => prop.theme.colors.red};
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    padding: 15px 0px;

    &:hover{
        cursor: pointer;
    }
`


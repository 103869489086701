import axios from 'axios';

export const makeTransaction = async (token, user, cartId) => {
    const url = `${process.env.REACT_APP_TEST_URL}/transactions`;

    const info = { token, user, carId: cartId };

    const { data } = await axios.post(url, info);

    return data;
}

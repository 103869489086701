import React, { useEffect, useState } from 'react';
import { CartPaymentMethod, CartDelivery, CartPaymentContainer, CartSubtotal, CartTotal, CartTotalContainer, CartPaymentName, CartPaymentNameContainer, CartPaymentCancel } from './style';
import { BsCash, BsCreditCard2Front, BsChevronRight } from 'react-icons/bs';
import { useTranslation } from "react-i18next";
import { useCart } from '../../../context/cart';
import ConfirmDelete from '../ConfirmDelete';
import Link from '../../../components/Link';
import { payOrder } from '../../../services/payments/payOrder';
import { useShop } from '../../../context/shop';
import formatNumber from '../../../helpers/formatNumber';
import { useAddress } from '../../../context/address';
import { useParams } from 'react-router';
import { createQuote } from '../../../services/delivery/createQuote';
import propTypes from 'prop-types';
export default function CartPayment({ showAlert }) {
    const { t } = useTranslation();
    const { storeId } = useParams();
    const [showCancel, setShowCancel] = useState(false);
    const { shop, payment, deliveryOption } = useShop();
    const [delivery,] = deliveryOption;
    const { settings } = shop;
    const { deliveryAddress } = useAddress();
    const [address,] = deliveryAddress;
    const { subtotal, total, resetCart, cart, deliveryAmount } = useCart();
    const [, setDeliveryAmount] = deliveryAmount;
    const [pay,] = payment;
    const [quote, setQuote] = useState(null);

    useEffect(() => {
        if (address.lat && address.lng) {
            const orderInfo = {
                campaign: storeId,
                description: cart.products.map((product) => product.name).join(", "),
                clientName: cart.clientId === 'qrcode' ? shop.name : cart.clientName,
                sale: cart.id,
                lat: address.lat,
                long: address.lng,
                address: address.additionalInfo ? `${address.description}. ${address.additionalInfo}` : address.description
            }
            createQuote(orderInfo).then((result) => {
                setQuote(result.deliveryAmount);
                setDeliveryAmount(result.deliveryAmount);
            });
        }
    }, [storeId, shop, cart, address, setDeliveryAmount]);

    const payInfo = async () => {
        let params = {
            channelId: cart.channelId, 
            username: cart.clientUsername,
            text: 'CANCEL_ORDER', 
            idSale: cart.id, 
            paymentMethod: pay
        
        }
        const url = await payOrder(params);
        window.open('https://'+url, '_blank');
    }


    return <CartPaymentContainer>
        <CartTotalContainer>
            {delivery && <CartSubtotal>
                <div>
                    {t('cart.subtotal')}
                </div>
                <div>
                    {formatNumber(subtotal)}
                </div>
            </CartSubtotal>}
            {delivery && <CartDelivery>
                <div>
                    {t('cart.delivery')}
                </div>
                <div>
                    {quote ? formatNumber(quote) : '-'}
                </div>
            </CartDelivery>}
            <CartTotal>
                <div>
                    {t('cart.total')}
                </div>
                <div>
                    {formatNumber(total)}
                </div>
            </CartTotal>
        </CartTotalContainer>
        <CartPaymentMethod cash={pay === 'cash'} >
            {
                pay === 'cash' ?
                    <CartPaymentNameContainer>
                        <BsCash />
                        <CartPaymentName>
                            {t('cart.cash')}
                        </CartPaymentName>
                    </CartPaymentNameContainer>
                    :
                    <CartPaymentNameContainer>
                        <BsCreditCard2Front />
                        <CartPaymentName>
                            {t('cart.card')}
                        </CartPaymentName>
                    </CartPaymentNameContainer>
            }
            {(settings?.cardPayments && settings?.cashPayments) &&
                <Link to="/payment-options">
                    <BsChevronRight />
                </Link>
            }
            <ConfirmDelete show={showCancel} onClose={() => { setShowCancel(false) }} onConfirm={() => {
                resetCart(); payInfo(); showAlert(true);
            }} />
        </CartPaymentMethod>
        <CartPaymentCancel onClick={() => { setShowCancel(true) }}
        >
            {t('cart.cancelOrder')}
        </CartPaymentCancel>
    </CartPaymentContainer>;
}


CartPayment.propTypes = {
    showAlert: propTypes.func,
}
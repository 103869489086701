import axios from 'axios';
import { productBackToFront } from '../adapters/product';

export const deleteProduct = async ({ cartId, products }) => {
    const url = `${process.env.REACT_APP_TEST_URL}/sale/deleteProduct`;

    const sale = { sale: { _id: cartId, car: products.map((product) => { return { idProduct: product.id, optionsGroup: product.optionsGroup } }) } };

    const { data } = await axios.put(url, sale);

    return { ...data.sale, car: data.sale.car.map(productBackToFront) };
}



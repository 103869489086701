import React, { createContext, useContext } from 'react'
import { useState } from 'react';

export const AddressContext = createContext(null)

export default function AddressProvider({ children }) {
    const [deliveryAddress, setDeliveryAddress] = useState({ lat: null, lng: null, description: null, additionalInfo: null });

    const address = {
        deliveryAddress: [deliveryAddress, setDeliveryAddress],
    }

    return <AddressContext.Provider value={address}>{children}</AddressContext.Provider>
}

export const useAddress = () => {
    return useContext(AddressContext);
}
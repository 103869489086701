
export const getProduct = async (_id) => {
    const url = `${process.env.REACT_APP_TEST_URL}/get-product-id?productId=${_id}`;
    const resp = await fetch(url);
    const data = await resp.json();
    const imageUrl = Array.isArray(data.imageUrl) ? data.imageUrl : [data.imageUrl];

    return {
        ...data,
        imageUrl
    };
}
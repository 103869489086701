import React from "react";
import { bool, func, number, string } from "prop-types";
import { ProductFooter, Stock } from "../style";
import { useCart } from "../../../context/cart";
import { useParams } from "react-router";
import ProductCounter from "../ProductCounter";
import NoProductFooter from "../NoProductFooter";
import { useTranslation } from "react-i18next";
import Footer from "../../../components/Footer";
import Button from "../../../components/Button";

export default function ProductDetailFooter({
  stock,
  quantity,
  onAdd,
  onDelete,
  onClick,
  buttonTitle,
  totalQuantityEdit,
  disabledButton,
}) {
  const { t } = useTranslation();
  const { cart } = useCart();
  const { productId } = useParams();

  const cartQuantity = cart?.products
    ? cart?.products?.map((product) => {
        return {
          id: product.id,
          quantity: product.quantity,
        };
      })
    : [];

  const cartProduct = cartQuantity?.filter((e) => productId === e.id);
  let totalQ = cartProduct.reduce((total, { quantity }) => total + quantity, 0);

  let totalQuantity = stock - totalQ - quantity;

  const totals = () => {
    if (totalQuantity) {
      return totalQuantity;
    }
    return totalQuantityEdit;
  };

  return (
    <>
      {stock === 0 || totalQuantity === -1 ? (
        <NoProductFooter />
      ) : (
        <>
          <ProductFooter
            maxStock={
              quantity === stock ||
              totalQuantity === 0 ||
              totalQuantityEdit === 0
            }
          >
            <ProductCounter
              value={quantity}
              onAdd={onAdd}
              onDelete={onDelete}
              stock={stock}
              totalQuantity={totals()}
            />
            {quantity === stock && (
              <Stock>{t("products.existence", { stock })}</Stock>
            )}
            {(totalQuantity === 0 || totalQuantityEdit === 0) && (
              <Stock>{t("products.existence-cart", { totalQuantity })}</Stock>
            )}
          </ProductFooter>
          <Footer>
            <Button
              title={buttonTitle}
              disabled={disabledButton}
              onClick={onClick}
            />
          </Footer>
        </>
      )}
    </>
  );
}

ProductDetailFooter.propTypes = {
  stock: number,
  quantity: number,
  onAdd: func,
  onDelete: func,
  onClick: func,
  buttonTitle: string,
  totalQuantityEdit: number,
  disabledButton: bool,
};
